import React, { useCallback, useState } from "react"
import { Helmet } from "react-helmet"

import "./index.scss"

const gifs = [
    "https://i.giphy.com/media/anS1ZNfpzXSjm/giphy.webp",
    "https://i.giphy.com/media/NiVtPpCWh2n3G/giphy.webp",
    "https://i.giphy.com/media/hmoQR9qoEStUI/giphy.webp",
    "https://i.giphy.com/media/RHOwKRZSx8iZiREGV2/giphy.webp",
    "https://i.giphy.com/media/3iiwqPF9noqdy/giphy.webp",
    "https://i.giphy.com/media/dKZRRvQhrT2HS/giphy.webp",
    "https://i.giphy.com/media/m6tNZJt9cG3ss/giphy.webp",
    "https://i.giphy.com/media/kx7XPH5Cc2irGixWKd/giphy.webp",
    "https://i.giphy.com/media/523wskgYnlofrByl7S/giphy.webp",
    "https://i.giphy.com/media/syKCkIGktGtNK/giphy.webp",
    "https://i.giphy.com/media/H6MxVAi7wu15m/giphy.webp",
    "https://i.giphy.com/media/3X94lZZC59ZgA/giphy.webp",
    "https://i.giphy.com/media/4gz3GCi5XvYHe/giphy.webp",
    "https://i.giphy.com/media/YcRUDQ5JrbHdS/giphy.webp",
    "https://i.giphy.com/media/s9kJXPK6jjoME/giphy.webp",
];

export default () => {
  const imageIndex = Math.floor(Math.random()*(gifs.length));
  const [index, setIndex] = useState(imageIndex);

  const nextIndex = useCallback(() => {
    const maxValue = gifs.length - 1;
    if (index === maxValue) setIndex(0);
    else setIndex(index + 1)
  }, [setIndex, index]);

  return (
    <div className="ikkefrisk">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <title>Ikke frisk</title>
      </Helmet>
      <div>
      <div className="gif-wrapper">
        <img src={gifs[index]} />
      </div>
      <div className="btn-wrapper">
        <button className="new-gif-btn" onClick={nextIndex}>Ikke frisk</button>
      </div>
      </div>
    </div>
  );
};
